import { PasswordsContext } from '../context/PasswordsContext'
import { useContext } from 'react'

export const usePasswordsContext = () => {
    const context = useContext(PasswordsContext)

    if(!context){
        throw Error('usePasswordsContext must be used within a PasswordsContextProvider')
    }

    return context
}