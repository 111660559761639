import { useState } from 'react'
import { usePasswordsContext } from '../hooks/usePasswordsContext'
import { useAuthContext } from '../hooks/useAuthContext'

const PasswordForm = () => {

    const { dispatch } = usePasswordsContext()
    const [organisation, setOrganisation] = useState('')
    const [url, setUrl] = useState('')
    const [email, setEmail] = useState('')
    const [user_name, setUser_name] = useState('')
    const [pass_word, setPass_word] = useState('')
    const [error, setError] = useState(null)
    const [emptyFields, setEmptyFields] = useState([])
    const {user} = useAuthContext()

    const handleSubmit = async (e) => {
        e.preventDefault()

        if(!user){
            setError('You must be logged in')
            return
        }

        const password = {organisation, url, email, user_name, pass_word}
  
        const response = await fetch('/api/passwords', {
            method: 'POST',
            body: JSON.stringify(password),
            headers: {
                'content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
        console.log('before')
        const json = await response.json()

        if(!response.ok){
            // console.log(json)
            setError(json.error)
            setEmptyFields(json.emptyFields)
        }
        if(response.ok){
            setOrganisation('')
            setUrl('')
            setEmail('')
            setUser_name('')
            setPass_word('')
            setError(null)
            setEmptyFields([])
            console.log('new password added', json)
            dispatch({type: 'CREATE_PASSWORD', payload: json})
        }
    }

    return ( 
        <form className="create" onSubmit={handleSubmit}>
            <h3>Add New Password</h3>
            <label>Organisation:</label>
            <input type="text" onChange={(e) => setOrganisation(e.target.value)} value={organisation} className={emptyFields.includes('organisation') ? 'error' : ''}/>
            <label>URL:</label>
            <input type="text" onChange={(e) => setUrl(e.target.value)} value={url} className={emptyFields.includes('url') ? 'error' : ''}/>
            <label>Email:</label>
            <input type="text" onChange={(e) => setEmail(e.target.value)} value={email} className={emptyFields.includes('email') ? 'error' : ''}/>
            <label>Username:</label>
            <input type="text" onChange={(e) => setUser_name(e.target.value)} value={user_name} className={emptyFields.includes('user_name') ? 'error' : ''}/>
            <label>Password:</label>
            <input type="text" onChange={(e) => setPass_word(e.target.value)} value={pass_word} className={emptyFields.includes('pass_word') ? 'error' : ''}/>
            <button>Add Password</button>
            {error && <div className='error'>{error}</div>}
        </form>
     );
}
 
export default PasswordForm;