import { usePasswordsContext } from '../hooks/usePasswordsContext'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import { useAuthContext } from '../hooks/useAuthContext'

const PasswordDetails = ({password}) => {

    const { dispatch } = usePasswordsContext()
    const {user} = useAuthContext()
    
    if(!user){
        return
    }

    const handleDelete = async () => {
        const response = await fetch('/api/passwords/' + password._id, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        
        const json = await response.json()
        
        if(response.ok){
            dispatch({type: 'DELETE_PASSWORD', payload: json})
        }
    }

    return ( 
        <div className="password-details">
            <h4>{password.organisation}</h4>
            <p><strong>URL: </strong>{password.url}</p>
            <p><strong>Email: </strong>{password.email}</p>
            <p><strong>Username: </strong>{password.user_name}</p>
            <p><strong>Password: </strong>{password.pass_word}</p>
            <p>{formatDistanceToNow(new Date(password.createdAt), { addSuffix: true })}</p>
            <span className='material-symbols-outlined' onClick={handleDelete}>delete</span>
        </div>
    );
}
 
export default PasswordDetails;